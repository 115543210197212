import { User } from "@digi-waste/generated-models";
import { Button, ButtonGroup, Modal } from "@lego/klik-ui";
import { DeleteBold } from "@lego/klik-ui-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { request } from "../../utils/api";

interface IDeleteModalProps {
    user: User | undefined;
    refetch: () => void;
    isOpen: boolean;
    onClose: () => void;
}

export const DeleteUserModal: React.FC<IDeleteModalProps> = ({
    user,
    refetch,
    isOpen,
    onClose,
}) => {
    const { t } = useTranslation();

    const { mutate, isLoading } = useMutation<unknown, unknown, User>(
        async ({ id }) => {
            await request(`users/${id}`, {
                method: 'DELETE',
            })
        },
        {
            onSuccess: () => {
                refetch()
                onClose()
            }
        },
    )

    if (!user) {
        return null
    }

    return (
        <Modal isOpen={isOpen} isCentered
            onClose={onClose}
        >
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header>{t('user_table.delete_user.header')}</Modal.Header>
                <Modal.CloseButton />
                <Modal.Body>
                    <p>{t('user_table.delete_user.info')} {user.username}.</p><br></br>
                    <ButtonGroup>
                        <Button variant="ghost" size="md" isLoading={isLoading}
                            onClick={onClose}
                        >
                            {t('user_table.cancel')}
                        </Button>
                        <Button size="md" leftIcon={<DeleteBold />} colorScheme="error" isLoading={isLoading}
                            onClick={() => void mutate(user)}
                        >
                            {t('user_table.delete')}
                        </Button>
                    </ButtonGroup>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    )
}
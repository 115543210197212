import { Password, User } from "@digi-waste/generated-models";
import { Button, ButtonGroup, Modal, Text } from "@lego/klik-ui";
import { DeleteBold } from "@lego/klik-ui-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { request } from "../../utils/api";

interface IChangePasswordModalProps {
    user: User | undefined;
    isOpen: boolean;
    onClose: () => void;
}

export const ResetPasswordModal: React.FC<IChangePasswordModalProps> = ({
    user,
    isOpen,
    onClose
}) => {
    const { t } = useTranslation();

    const { data, mutate, reset, isLoading } = useMutation<Password, unknown, User>(
        async ({ id }) => {
            return await request(`users/${id}/resetPassword`, {
                method: 'POST'
            })
        }
    )

    if (!user) {
        return null
    }

    return (
        <Modal isOpen={isOpen} isCentered
            onClose={() => {
                onClose()
                reset()
            }}
        >
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header>{t('user_table.reset_password.header')}</Modal.Header>
                <Modal.CloseButton />
                <Modal.Body>
                    {data ?
                        <>
                            <Text>{t(`user_table.new_password`)}</Text><Text fontWeight="bold">{data.password}</Text>
                            <Button variant="ghost" size="md" onClick={() => {
                                onClose()
                                reset()
                            }}>
                                {t('user_table.cancel')}
                            </Button>
                        </>
                        :
                        <>
                            <Text mb="15px">{t(`user_table.reset_password.info`, { username: user.username })}</Text>
                            <ButtonGroup>
                                <Button variant="ghost" size="md" onClick={onClose} isLoading={isLoading}>
                                    {t('user_table.cancel')}
                                </Button>
                                <Button size="md" leftIcon={<DeleteBold />} colorScheme="warning" variant="var400" isLoading={isLoading}
                                    onClick={() => void mutate(user)}
                                >
                                    {t('user_table.reset')}
                                </Button>
                            </ButtonGroup>
                        </>}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    )
}
import { Button, Input, Modal, useDisclosure } from "@lego/klik-ui";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useMutation } from 'react-query';
import { ILoginForm } from "../pages/LoginPage";
import { Log } from "../utils/log";
import { FormField } from "./FormField";


interface IProps {
    user: ILoginForm;
    passwordReset: boolean;
}

interface IPasswordResetForm {
    newPassword: string;
    repeatNewPassword: string;
}

export const PasswordReset: React.FC<IProps> = ({ user, passwordReset }) => {
    const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { t } = useTranslation();

    // Open modal when user needs to reset password
    useEffect(() => {
        if (passwordReset) {
            onOpen();
        }
    }, [onOpen, passwordReset])

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        reset,
    } = useForm<IPasswordResetForm>();

    useEffect(() => {
        if (isSubmitted) {
            // Reset form values on submission either and close modal
            reset();
            onClose();
        }
    }, [isSubmitted, onClose, reset])

    const { isLoading, mutate } = useMutation<unknown, unknown, IPasswordResetForm>(
        async ({ newPassword, repeatNewPassword }) => {
            // Check if password match
            if (newPassword !== repeatNewPassword) {
                setError('repeatNewPassword', {
                    message: t('pass_reset.no_match'),
                })
                return;
            }
            try {
                // First login, then use the promise to reset user password
                await Auth.signIn(user.username.toLowerCase().trim(), user.password)
                    .then((userLogin) => {
                        Auth.completeNewPassword(userLogin, newPassword)
                            // If successful, do submission effect
                            .then(() => setIsSubmitted(true))
                            // If rejected set error
                            .catch(e => setError('newPassword', {
                                message: t('pass_reset.requirements'),
                            }));
                    })
            } catch (ex: unknown) {
                Log.error(ex);
                setError('newPassword', {
                    message: t('pass_reset.error'),
                });
            }

        },
        {
            onError: (error) => {
                Log.error(error);
                setError('newPassword', {
                    message: t('pass_reset.error'),
                });
            },
        }
    );

    return (
        <React.Fragment>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered
                onClose={() => {
                    onClose();
                    reset();
                }}
            >
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Header>{t('pass_reset.header')}</Modal.Header>
                    <Modal.CloseButton />
                    <Modal.Body>
                        <p>{t('pass_reset.info')}</p><br></br>
                        <form onSubmit={handleSubmit((values) => void mutate(values))}>
                            <FormField
                                error={errors.newPassword?.message}
                                isRequired={true}
                                label={t('pass_reset.new_password')}
                            >
                                <Input
                                    letterSpacing={3}
                                    type="password"
                                    color="black"
                                    {...register('newPassword', {
                                        required: true,
                                    })}
                                />
                            </FormField>
                            <FormField
                                error={errors.repeatNewPassword?.message}
                                isRequired={true}
                                label={t('pass_reset.repeat_password')}
                            >
                                <Input
                                    letterSpacing={3}
                                    type="password"
                                    color="black"
                                    {...register('repeatNewPassword', {
                                        required: true,
                                    })}
                                />
                            </FormField>
                            <Button
                                colorScheme="warning"
                                variant="var400"
                                size="lg"
                                isFullWidth={true}
                                type="submit"
                                isLoading={isLoading}
                                mt="15px"
                            >
                                {t('pass_reset.reset_password')}
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </React.Fragment>
    )
}
import { Area, MixedWasteReport } from '@digi-waste/generated-models';
import {
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  FormControl,
  IconButton,
  InlineNotification,
  Modal,
  NumberInput,
  Select,
  Stack,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@lego/klik-ui';
import { EditBold } from '@lego/klik-ui-icons';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useAuth } from '../../../auth/auth-provider';
import { request } from '../../../utils/api';
import { Log } from '../../../utils/log';
import { IRegistrationForm } from '../../../utils/types/custom';

interface IReportContainerProps {
  cancelRegistration: () => void;
  areas: Area[];
}

// Shows modal with report container where user can select area and number of containers to report
export const ReportContainer: React.FC<IReportContainerProps> = ({ cancelRegistration, areas }) => {
  const { t } = useTranslation(['app', 'db']);

  const { locationId } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [areaId, setAreaId] = useState('');
  const [count, setCount] = useState(1);

  const { getValues } = useFormContext<IRegistrationForm>();

  const openModal = useCallback(() => {
    setAreaId(getValues('areaId'));
    setCount(1);
    onOpen();
  }, [getValues, onOpen]);

  const toast = useToast();

  const showToast = useCallback(
    (options: { variant: 'error' | 'success'; title: string; description: string }) => {
      toast({
        position: 'top-right',
        duration: 3000,
        render: ({ onClose }) => (
          <InlineNotification variant={options.variant}>
            <InlineNotification.Content alignItems="flex-start" flexDirection="column">
              <InlineNotification.Title>{options.title}</InlineNotification.Title>
              <InlineNotification.Description>{options.description}</InlineNotification.Description>
            </InlineNotification.Content>
            <CloseButton onClick={onClose} />
          </InlineNotification>
        ),
      });
    },
    [toast]
  );

  const { isLoading, mutate: reportContainer } = useMutation(
    async (values: MixedWasteReport): Promise<void> => {
      await request(`mixedReport`, {
        method: 'POST',
        body: JSON.stringify(values),
      });
    },
    {
      onSuccess: (res, data) => {
        cancelRegistration();
        onClose();

        showToast({
          variant: 'success',
          title: t('rep_succcess_page.report_successfully'),
          description: t('regi_page.registration_restart'),
        });
      },
      onError: (x) => {
        Log.error(x);
        showToast({
          variant: 'error',
          title: t('regi_page.report_failed'),
          description: t('regi_page.try_again'),
        });
      },
    }
  );

  const screensize = useBreakpointValue({ base: 'other', lg: 'lg' });
  const isLarge = screensize === 'lg';

  const renderModal = (): JSX.Element => {
    return (
      <Modal hideIcon={true} isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header fontSize="sm">{t('report_container.mixed_content')}</Modal.Header>

          <Modal.CloseButton />

          <Modal.Body>
            <Stack
              alignItems={{ md: 'center', base: 'left' }}
              spacing="8"
              color="black"
              direction={{ md: 'row', base: 'column' }}
            >
              <Box>
                <FormControl>
                  <FormControl.Label>{t('report_container.container_count')}</FormControl.Label>
                  <NumberInput
                    isFullWidth
                    defaultValue={1}
                    value={count}
                    min={1}
                    onChange={(value) => setCount(parseInt(value))}
                  >
                    <NumberInput.Decrement />
                    <NumberInput.Field />
                    <NumberInput.Increment />
                  </NumberInput>
                </FormControl>
              </Box>
              <Box flexGrow="1">
                <FormControl>
                  <FormControl.Label>{t('report_container.main_area')}</FormControl.Label>
                  <Select
                    placeholder={t('report_container.ask_for_selection')}
                    value={areaId}
                    onChange={(e) => setAreaId(e.target.value)}
                  >
                    {
                      //TODO check and fix
                      areas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {t(`db:areas.${area.name}`)}
                        </option>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Modal.Body>

          <Modal.Footer>
            <ButtonGroup>
              <Button
                onClick={() =>
                  reportContainer({
                    locationId,
                    areaId,
                    count,
                  })
                }
                isDisabled={!areaId}
                isLoading={isLoading}
              >
                {t('report_container.report')}
              </Button>
              <Button onClick={onClose} variant="outline">
                {t('shared.cancel')}
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <Box>
      {isLarge ? (
        <Button
          leftIcon={<EditBold />}
          colorScheme="warning"
          variant="var400"
          borderRadius="40"
          onClick={openModal}
        >
          {t('regi_page.report_container')}
        </Button>
      ) : (
        <IconButton
          aria-label={t('regi_page.report_container')}
          icon={<EditBold />}
          colorScheme="warning"
          variant="var400"
          borderRadius="40"
          onClick={openModal}
        ></IconButton>
      )}

      {isOpen && renderModal()}
    </Box>
  );
};

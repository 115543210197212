import { Area, CreateRegistration, Registration, WasteType } from '@digi-waste/generated-models';
import { Box, Button, Flex, Menu, Stepper, Text } from '@lego/klik-ui';
import { ArrowDownBold, LogOut, Settings } from '@lego/klik-ui-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { FooterNavigation } from '../components/registration/components/FooterNavigation';
import { ReportContainer } from '../components/registration/components/ReportContainer';
import { AreaStep } from '../components/registration/steps/AreaStep';
import { CategoryStep } from '../components/registration/steps/CategoryStep';
import { ContainerStep } from '../components/registration/steps/ContainerStep';
import { WasteTypeStep } from '../components/registration/steps/WasteTypeStep';
import { WeightStep } from '../components/registration/steps/WeightStep';
import { request } from '../utils/api';
import { default as i18n, default as i18next, languages } from '../utils/i18n';
import { Log } from '../utils/log';
import { IRegistrationForm } from '../utils/types/custom';

enum Steps {
  Area = 'Area',
  Category = 'Category',
  WasteType = 'WasteType',
  Container = 'Container',
  Weight = 'Weight',
}

type TranslationKeys = 'db:areas' | 'db:containers' | 'db:waste_types' | 'app:shared';

const StepMap: Record<
  Steps,
  {
    title: string;
    formValue: keyof IRegistrationForm;
    translationKey?: TranslationKeys;
  }
> = {
  [Steps.Area]: { title: 'regi_stepper.area', formValue: 'areaName', translationKey: 'db:areas' },
  [Steps.Category]: {
    title: 'regi_stepper.category',
    formValue: 'categoryName',
    translationKey: 'app:shared',
  },
  [Steps.WasteType]: {
    title: 'regi_stepper.wastetype',
    formValue: 'wasteTypeName',
    translationKey: 'db:waste_types',
  },
  [Steps.Container]: {
    title: 'regi_stepper.container',
    formValue: 'containerName',
    translationKey: 'db:containers',
  },
  [Steps.Weight]: { title: 'regi_stepper.weight', formValue: 'weight' },
};

const steps = [Steps.Area, Steps.Category, Steps.WasteType, Steps.Container, Steps.Weight];

// eslint-disable-next-line max-lines-per-function
export const RegistrationPage: React.FC = () => {
  const { locationId, logout, isAdmin, isSupervisor } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation(['app', 'db']);

  const form = useForm<IRegistrationForm>({
    defaultValues: {
      locationId,
      weight: '0',
    },
  });

  const { handleSubmit, getValues, setValue, reset, watch } = form;

  // If we got state, we came back here from registration-complete screen and we are editing last entry
  useEffect(() => {
    if (state) {
      reset(state as IRegistrationForm);
      setCompletedSteps(steps.map((x, i) => i + 1));
      setActiveStep(steps.length - 1);
    }
  }, [state, reset, setCompletedSteps, setActiveStep]);

  const stepValueColor = (step: Steps): string => {
    const value = getValues(StepMap[step].formValue);

    if (!value || value === '0') {
      return 'light-blue.300';
    }

    return 'white';
  };

  const stepValueName = (step: Steps, traslationKey?: TranslationKeys): string => {
    const value = getValues(StepMap[step].formValue);

    if (!value || value === '0') {
      return t('regi_stepper.value_not_available');
    }

    if (StepMap[step].formValue === 'weight') {
      return `${t('intlNumber', { val: value })} kg`;
    }

    return traslationKey ? t(`${traslationKey}.${value}`) : `${value}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  useEffect(() => setValue('locationId', locationId), [setValue, locationId]);

  const { isLoading, mutate: handleSubmitForm } = useMutation(
    async (values: IRegistrationForm): Promise<Registration> => {
      const data: Registration | CreateRegistration = {
        id: values.id,
        locationId: values.locationId,
        areaId: values.areaId,
        wasteTypeId: values.wasteTypeId,
        containerId: values.containerId,
        weight: +values.weight,
      };

      // Updated entry
      if ('id' in data && data.id) {
        return await request<Registration>(`registrations/${data.id}`, {
          method: 'PUT',
          body: JSON.stringify(data),
        });
        // New entry
      } else {
        return await request<Registration>(`registrations`, {
          method: 'POST',
          body: JSON.stringify(data),
        });
      }
    },
    {
      onSuccess: (res, data) => {
        navigate(`/registration-complete`, { state: { ...data, id: res.id }, replace: true });
      },
      onError: (x) => {
        Log.error(x);
        navigate(`/registration-failed`, { replace: true });
      },
    }
  );

  const handleNextStep = useCallback(() => {
    setCompletedSteps((prev) => [activeStep + 1, ...prev]);
    setActiveStep(activeStep + 1);
  }, [activeStep, setCompletedSteps, setActiveStep]);

  const hasCurrentStepValue = useCallback((): boolean => {
    return getValues(StepMap[steps[activeStep]].formValue) !== undefined;
  }, [getValues, activeStep]);

  const isCurrentStep = useCallback((step: Steps) => steps[activeStep] === step, [activeStep]);

  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  const handlePreviousStep = useCallback(() => setActiveStep((prev) => prev - 1), [setActiveStep]);

  const cancelRegistration = useCallback(() => {
    reset({
      locationId,
      weight: '0',
    });

    setActiveStep(0);
    setCompletedSteps([]);
  }, [reset, locationId, setActiveStep]);

  const areaId = watch('areaId');

  const {
    data: wasteTypes,
    isLoading: isLoadingWasteTypes,
    isError: isErrorWasteTypes,
  } = useQuery(
    ['wasteTypes', areaId],
    async () => {
      return await request<WasteType[]>(`locations/${locationId}/areas/${areaId}/wasteTypes`);
    },
    {
      enabled: !!areaId,
    }
  );

  const {
    data: areas,
    isLoading: isLoadingAreas,
    isError: isErrorAreas,
  } = useQuery('areas', async () => {
    return await request<Area[]>(`locations/${locationId}/areas`);
  });

  if (isLoading || isLoadingWasteTypes || isLoadingAreas) {
    return <LoadingSpinner />;
  }

  if (isErrorWasteTypes) {
    return <Text>Something went wrong loading waste types!</Text>;
  }

  if (isErrorAreas || !areas) {
    return <Text>Something went wrong loading areas!</Text>;
  }

  return (
    <Flex direction="column" height="100vh" width="100vw">
      <FormProvider {...form}>
        <Flex flex="2" bg="primary" align="center" p="4">
          <Flex width="100vw" justify="center">
            <Box mb={8} flex="1">
              <Stepper
                activeStep={activeStep + 1}
                colorScheme="copper"
                completedSteps={completedSteps}
                // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                onChange={(index) => setActiveStep(index - 1)}
                orientation={'horizontal'}
                alignment="left"
              >
                {steps.map((step) => (
                  <Stepper.Step aria-readonly={undefined} key={step}>
                    <Stepper.Label color={isCurrentStep(step) ? 'warning.400' : 'light-blue.300'}>
                      {t(StepMap[step].title)}
                    </Stepper.Label>
                    <Stepper.Label color={stepValueColor(step)}>
                      {stepValueName(step, StepMap[step].translationKey)}
                    </Stepper.Label>
                  </Stepper.Step>
                ))}
              </Stepper>
            </Box>
          </Flex>
          <Box mr={5}>
            <Menu>
              <Menu.Button
                as={Button}
                rightIcon={<ArrowDownBold />}
                background="transparent"
                borderWidth="2px"
                borderColor="white"
                _hover={{ background: 'transparent' }}
                _active={{ background: 'transparent' }}
              >
                {i18next.language}
              </Menu.Button>
              <Menu.List border={0} borderRadius={0} background="#E8F2FA">
                {languages.map((lang) => (
                  <Menu.Item
                    key={lang.code}
                    isDisabled={lang.code === i18next.language}
                    onClick={() => void i18n.changeLanguage(lang.code)}
                  >
                    {lang.code} - {lang.name}
                  </Menu.Item>
                ))}
              </Menu.List>
            </Menu>
          </Box>
          <Box mr={5}>
            <Button onClick={logout}>
              <LogOut />
            </Button>
          </Box>
          {isAdmin || isSupervisor ?
            <Box >
              <Button onClick={() => navigate("/admin")}>
                <Settings />
              </Button>
            </Box>
            :
            null
          }
        </Flex>
        <Flex flex="9" overflow="auto" w="100vw">
          <form>
            <AreaStep
              goToNextStep={handleNextStep}
              hidden={!isCurrentStep(Steps.Area)}
              areas={areas}
            />
            <CategoryStep
              goToNextStep={handleNextStep}
              hidden={!isCurrentStep(Steps.Category)}
              wasteTypes={wasteTypes}
            />
            <WasteTypeStep
              goToNextStep={handleNextStep}
              hidden={!isCurrentStep(Steps.WasteType)}
              wasteTypes={wasteTypes}
            />
            <ContainerStep goToNextStep={handleNextStep} hidden={!isCurrentStep(Steps.Container)} />
            <WeightStep
              goToNextStep={() => {
                // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                void handleSubmit((values) => handleSubmitForm(values))();
              }}
              hidden={!isCurrentStep(Steps.Weight)}
              isLoading={isLoading}
            />
          </form>
        </Flex>
        <Flex flex="1" bg="primary" width="100vw" align="center" p="4">
          <FooterNavigation
            canGoBack={!isCurrentStep(Steps.Area)}
            canGoForward={!isCurrentStep(Steps.Weight) && hasCurrentStepValue()}
            cancelRegistration={cancelRegistration}
            goBack={handlePreviousStep}
            goForward={handleNextStep}
          />
          <ReportContainer cancelRegistration={cancelRegistration} areas={areas} />
        </Flex>
      </FormProvider>
    </Flex>
  );
};

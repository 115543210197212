import { KlikProvider } from '@lego/klik-ui';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './auth/auth-provider';
import { LoadingSpinner } from './components/LoadingSpinner';
import { theme } from './theme';
import { isFetchError } from './utils/FetchError';

// If returned error is 4XX (user error) we will not retry
// Otherwise we will do 1 retry by default
const retry = (failureCount: number, error: unknown) => {
  if (isFetchError(error) && Math.floor(error.statusCode / 100) === 4) {
    return false;
  }

  return failureCount <= 1;
};

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // Default stale Time 24 hours
      staleTime: 24 * 60 * 60 * 1000,
      retry,
      // Retry delay gets bigger with each retry (1s, 2s, 4s, 8s, 16s, 30s, 30s...)
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
    // Do not retry on mutations
    mutations: {
      retry: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <AuthProvider>
          <KlikProvider theme={theme}>
            <Suspense fallback={<LoadingSpinner />}>
              <App />
            </Suspense>
          </KlikProvider>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

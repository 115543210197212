import { Location, User } from "@digi-waste/generated-models";
import { Button, Container, Flex, IconButton, Text, Tooltip, useDisclosure } from "@lego/klik-ui";
import { DeleteBold, EditBold, PlusBold } from "@lego/klik-ui-icons";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { request } from "../../utils/api";
import { CreateUserModal } from "./CreateUserModal";
import { DeleteUserModal } from "./DeleteUserModal";
import { ResetPasswordModal } from "./ResetPasswordModal";
import { UserTableComponent } from "./UserTableComponent";

export const UserTable: React.FC = () => {
    const [locations, setLocations] = useState<Location[]>([]);
    const [user, setUser] = useState<User>();
    const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
    const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure({ defaultIsOpen: false })
    const { isOpen: isPasswordOpen, onClose: onPasswordClose, onOpen: onPasswordOpen } = useDisclosure({ defaultIsOpen: false })
    const { t } = useTranslation();

    const {
        data: users,
        isLoading,
        refetch,
    } = useQuery<User[]>('users', async () => {
        return await request<User[]>('users');
    })

    // Get locations on load
    useEffect(() => {
        const getLocations = async () => {
            await request<Location[]>('locations')
                .then((res) => setLocations(res))
        }
        getLocations()
    }, [])

    const columns = React.useMemo<ColumnDef<User>[]>(
        () => [
            {
                accessorKey: "username",
                header: t('user_table.columns.0'),
            },
            {
                accessorKey: "locationId",
                header: t('user_table.columns.1'),
                cell: ({ cell }) => {
                    var result = locations.find((location) => {
                        return location.id === cell.renderValue()
                    })
                    return (
                        <Text>{result?.name}</Text>
                    )
                }
            },
            {
                id: "Reset password",
                cell: ({ row }) => {
                    return (
                        <Tooltip label={t('user_table.columns.2')}>
                            <IconButton
                                aria-label="Reset password"
                                icon={<EditBold />}
                                onClick={() => {
                                    setUser(row.original)
                                    onPasswordOpen()
                                }}
                                variant="ghost"
                                size="sm"
                                disabled={!row.original.isActive}
                            />
                        </Tooltip>
                    )
                }
            },
            {
                id: "Delete user",
                cell: ({ row }) => {
                    return (
                        <Tooltip label={t('user_table.columns.3')}>
                            <IconButton
                                aria-label="Disable user"
                                icon={<DeleteBold />}
                                onClick={() => {
                                    setUser(row.original)
                                    onDeleteOpen()
                                }}
                                size="sm"
                                variant="ghost"
                                colorScheme="error"
                                disabled={!row.original.isActive}
                            />
                        </Tooltip>
                    )
                }
            }
        ],
        [locations, onDeleteOpen, onPasswordOpen, t]
    )
    const table = useReactTable<User>({
        data: users as User[],
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <Container maxW="container.md">
            <Flex me="3" ms="0" my="2">
                <Button size="md" variant="ghost" leftIcon={<PlusBold />} onClick={onOpen}>
                    {t('user_table.create_button')}
                </Button>
            </Flex>
            <UserTableComponent
                table={table}
                isLoading={isLoading}
            />
            <CreateUserModal refetch={refetch} locations={locations} isOpen={isOpen} onClose={onClose} />
            <DeleteUserModal user={user} refetch={refetch} isOpen={isDeleteOpen} onClose={onDeleteClose} />
            <ResetPasswordModal user={user} isOpen={isPasswordOpen} onClose={onPasswordClose} />
        </Container>
    )
}
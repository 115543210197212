import { CreateUser, Location, Password } from "@digi-waste/generated-models";
import { Button, Dropdown, Input, Modal, Text } from "@lego/klik-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { request } from "../../utils/api";
import { Log } from "../../utils/log";
import { FormField } from "../FormField";

interface ICreateModalProps {
    refetch: () => void;
    locations: Location[];
    isOpen: boolean;
    onClose: () => void;
}

export const CreateUserModal: React.FC<ICreateModalProps> = ({
    refetch,
    locations,
    isOpen,
    onClose,
}) => {
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm<CreateUser>();

    const { data, mutate, reset, isLoading } = useMutation<Password, unknown, CreateUser>(
        async ({ username, locationId }) => {
            return await request('users', {
                method: 'POST',
                body: JSON.stringify({
                    username: username.toLowerCase(),
                    locationId: locationId
                })
            })
        },
        {
            onError: (error: any) => {
                Log.error(error)
                if (error.errorCode === "INVALID_REQUEST_BODY") {
                    setError('username', {
                        message: t('user_table.create_user.regex_error'),
                    })
                    return;
                }
                setError('username', {
                    message: t('user_table.create_user.username_error'),
                });
            }
        }
    )

    return (
        <Modal isOpen={isOpen} isCentered closeOnOverlayClick={false}
            onClose={() => {
                onClose();
            }}
        >
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header>{t('user_table.create_user.header')}</Modal.Header>
                {data ? null : <Modal.CloseButton />}
                <Modal.Body>
                    {data ?
                        <>
                            <Text>{t('user_table.new_password')}</Text><Text fontWeight="bold">{data.password}</Text>
                            <Button variant="ghost" size="md" onClick={() => {
                                reset();
                                refetch();
                                onClose();
                            }}>
                                Close
                            </Button>
                        </>
                        :
                        <>
                            <Text mb="15px">{t('user_table.create_user.info')}</Text>
                            <form onSubmit={handleSubmit((values) => void mutate(values))}>
                                <FormField
                                    mb="12px"
                                    error={errors.username?.message}
                                    isRequired={true}
                                    label={t('user_table.username')}
                                >
                                    <Input
                                        type="text"
                                        color="black"
                                        {...register('username', {
                                            required: true,
                                        })}
                                    />
                                </FormField>
                                <FormField
                                    error={errors.locationId?.message}
                                    label={t('user_table.create_user.location')}
                                >
                                    <Dropdown>
                                        {locations!.map((location) => {
                                            return (
                                                <Dropdown.Option key={location.name} value={location.id}>
                                                    <Text>{location.name}</Text>
                                                </Dropdown.Option>
                                            )
                                        })}
                                    </Dropdown>
                                </FormField>
                                <Button
                                    colorScheme="warning"
                                    variant="var400"
                                    size="lg"
                                    isFullWidth={true}
                                    type="submit"
                                    isLoading={isLoading}
                                    mt="15px"
                                >
                                    {t('user_table.create_user.button')}
                                </Button>
                            </form>
                        </>
                    }
                </Modal.Body>
            </Modal.Content>
        </Modal>
    )
}
import { Button, ButtonGroup, IconButton, Modal, useDisclosure } from '@lego/klik-ui';
import { CrossBold } from '@lego/klik-ui-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  canCancel: boolean;
  cancelRegistration: () => void;
}

// Prompt "Are you sure you want to cancel your registration?" when user clicks on Cancel Registration button
export const CancelRegistration: React.FC<IProps> = ({ canCancel, cancelRegistration }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickEndProcessButton = () => {
    onClose();
    cancelRegistration();
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label={t('shared.cancel')}
        icon={<CrossBold />}
        isDisabled={!canCancel}
        variant="outline"
        color="copper.100"
        borderColor="copper.100"
        onClick={onOpen}
      />

      {isOpen && (
        <Modal hideIcon={true} isOpen={isOpen} onClose={onClose} variant="error" isCentered={true}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>{t('cancel.end_process')}</Modal.Header>
            <Modal.CloseButton />

            <Modal.Body>{t('cancel.confirmation')}</Modal.Body>

            <Modal.Footer>
              <ButtonGroup>
                <Button colorScheme="error" onClick={onClickEndProcessButton}>
                  {t('cancel.end_process_button')}
                </Button>
                <Button onClick={onClose} variant="outline">
                  {t('cancel.no_button')}
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </React.Fragment>
  );
};

import { CognitoGroups } from '@digi-waste/common';
import { User } from '@digi-waste/generated-models';
import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { request } from '../utils/api';
import { amplifyConfig } from '../utils/config-helper';
import { Log } from '../utils/log';
import { ICognitoUser } from '../utils/types/amplify';

// Configure Amplify
Amplify.configure(amplifyConfig);

// Values accesiible to components throught the Auth Provider
interface IAuthContext {
  userId: string;
  locationId: string;
  hasLoaded: boolean;
  isLoggingIn: boolean;
  isAdmin: boolean;
  isSupervisor: boolean
  logout: () => void;
}

// We are using RequireAuth guard to prevent the user from accessing the page that does not have configuration & locationId loaded
// For that reason we do not have their type as optional as majority of use cases have them loaded and we don't want to check for them everytime
// We are checking for them in the RequireAuth guard and redirecting to logging page if undefined
const AuthContext = React.createContext<IAuthContext>({
  userId: undefined as unknown as string,
  locationId: undefined as unknown as string,
  // This state indicates whenever the provider has loaded. It does not indicate if user is logged in successfuly and configuration is loaded
  hasLoaded: false,
  // This state indicates whenever we are trying to fetch user data
  isLoggingIn: false,
  isAdmin: false,
  isSupervisor: false,
  logout: () => null,
});

export const AuthProvider: FC = ({ children }) => {
  const [loadedData, setLoadedData] = useState<
    { userId: string; locationId: string } | undefined
  >();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false)
  const qc = useQueryClient();

  // We check if we are authenticated and load data from API
  const getCurrentUser = useCallback(async () => {
    setIsLoggingIn(true);
    try {
      // Check if we are authenticated (get currently logged in user)
      const user: ICognitoUser = await Auth.currentAuthenticatedUser();
      // Get configuration from API
      const data = await request<User>(`me`);
      setLoadedData({
        userId: data.id,
        locationId: data.locationId,
      });
      checkCognitoGroups(user)
    } catch (err: unknown) {
      Log.error('err', err);
    } finally {
      setHasLoaded(true);
      setIsLoggingIn(false);
    }
  }, [setLoadedData]);

  // Check if the user is in the administrator group
  const checkCognitoGroups = (user: ICognitoUser) => {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups']
    if (groups.includes(CognitoGroups.ADMINISTRATORS))
      setIsAdmin(true)
    if (groups.includes(CognitoGroups.WASTE_SUPERVISORS))
      setIsSupervisor(true)
  }

  // Logout and cleanup
  const logout = useCallback(async () => {
    // Clear all cached data
    qc.getQueryCache().clear();
    // Logout
    await Auth.signOut();
    // Set configuration to undefined
    setLoadedData(undefined);
    setIsLoggingIn(false);
  }, [qc]);

  // Listening to amplify events so we can change state accordingly, e.g. when refresh token expires
  const listenForAuthEvents = useCallback(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      Log.info('auth event', event, data);

      switch (event) {
        case 'signIn':
          void getCurrentUser();
          break;
        case 'signOut':
        case 'signIn_failure':
        case 'tokenRefresh_failure':
          setLoadedData(undefined);
          setIsLoggingIn(false);
          break;
        case 'custom0AuthState':
          break;
      }
    });
  }, [getCurrentUser]);

  // initialize when app is loaded
  useEffect(() => {
    void getCurrentUser();
    listenForAuthEvents();
  }, [listenForAuthEvents, getCurrentUser]);

  // Save memo of context value to avoid re-rendering
  const value = useMemo(
    () => ({
      hasLoaded,
      isLoggingIn,
      isAdmin,
      isSupervisor,
      logout,
      locationId: loadedData?.locationId as string,
      userId: loadedData?.userId as string,
    }),
    [loadedData, isLoggingIn, hasLoaded, logout, isAdmin, isSupervisor]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Export AuthContext to be used in components
export const useAuth = (): IAuthContext => React.useContext(AuthContext);

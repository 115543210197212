import { FormControl, HorizontalStack, IFormControlProps } from '@lego/klik-ui';
import React from 'react';

interface IFormFieldProps extends IFormControlProps {
  label: string;
  error?: string;
  helper?: string;
  isRequired?: boolean;
  labelColor?: string;
  labelRightElement?: JSX.Element;
}

// Styled form input field with helper and error message
export const FormField: React.FC<IFormFieldProps> = ({
  error,
  children,
  isRequired = false,
  helper,
  label,
  labelColor,
  labelRightElement = null,
  ...formControlProps
}) => {
  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired} {...formControlProps}>
      <HorizontalStack alignItems="center" justifyContent="space-between">
        <FormControl.Label color={labelColor ?? undefined}>{label}</FormControl.Label>
        {labelRightElement}
      </HorizontalStack>
      {children}
      {helper && <FormControl.HelperText>{helper}</FormControl.HelperText>}
      {error && <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>}
    </FormControl>
  );
};

import { Box, Button, Container, Flex, Input, Menu } from '@lego/klik-ui';
import { ArrowDownBold } from '@lego/klik-ui-icons';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';
import { FormField } from '../components/FormField';
import { PasswordReset } from '../components/PasswordReset';
import { default as i18n, default as i18next, languages } from '../utils/i18n';

export interface ILoginForm {
  username: string;
  password: string;
}

// Since part of loging process is redirection to SSO we will store original path under this index into local storage
const LOCAL_STORAGE_REDIRECT_ITEM_NAME = 'redirected-from';

export const LoginPage: React.FC = () => {
  const auth = useAuth();
  const loc = useLocation();
  const state = loc.state as { from: Location } | undefined;
  const { t } = useTranslation();
  const [passwordReset, setPasswordReset] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
  } = useForm<ILoginForm>();

  const { isLoading, mutate } = useMutation<unknown, unknown, ILoginForm>(
    async ({ username, password }) => {
      try {
        setPasswordReset(false);
        await Auth.signIn(username.toLowerCase().trim(), password)
          .then((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setPasswordReset(true);
            }
          });
      } catch (ex: unknown) {
        setError('password', {
          message: t('login_page.username_or_password'),
        });
      }
    },
    {
      onError: () => {
        setError('username', {
          message: t('login_page.login_error'),
        });
      },
    }
  );

  // Saving / retrieving redirect path from local storage
  useEffect(() => {
    if (state?.from) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_ITEM_NAME, state.from.pathname);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_REDIRECT_ITEM_NAME);
    }
  }, [state]);

  // If user is logged in we redirect him to the original path (if exists) or to the home page
  if (auth.userId) {
    const to = localStorage.getItem(LOCAL_STORAGE_REDIRECT_ITEM_NAME);

    if (to) {
      return <Navigate replace={true} to={to} />;
    } else {
      return <Navigate replace={true} to="/?success=true" />;
    }
  }

  return (
    <Flex direction="column" h="100vh">
      <PasswordReset user={getValues()} passwordReset={passwordReset} />
      <Flex flex="2" align="center" justify="center" fontSize="5xl">
        {t('login_page.welcome')}
      </Flex>
      <Flex flex="7" align="center" justify="center" bg="primary">
        <Container w="400px">
          <form onSubmit={handleSubmit((values) => void mutate(values))}>
            <FormField
              error={errors.username?.message}
              isRequired={true}
              color="white"
              label={t('login_page.username')}
            >
              <Input
                //letterSpacing={3}
                type="text"
                color="black"
                {...register('username', {
                  required: true,
                })}
              />
            </FormField>
            <FormField
              error={errors.password?.message}
              isRequired={true}
              color="white"
              label={t('login_page.password')}
            >
              <Input
                letterSpacing={3}
                type="password"
                color="black"
                {...register('password', {
                  required: true,
                })}
              />
            </FormField>
            <Button
              colorScheme="warning"
              variant="var400"
              size="lg"
              isFullWidth={true}
              type="submit"
              isLoading={isLoading || auth.isLoggingIn}
            >
              {t('login_page.login_button')}
            </Button>
          </form>
        </Container>
      </Flex>
      <Flex flex="3">
        <Flex flex="3" align="center" justify="left">
          <Box ml="8" color="black">
            <Box>{t('shared.get_help')}</Box>
          </Box>
        </Flex>
        <Flex flex="3" align="center" justify="right" margin={10}>
          <Menu>
            <Menu.Button as={Button} rightIcon={<ArrowDownBold />} borderWidth="2px">
              {i18next.language}
            </Menu.Button>
            <Menu.List border={0} borderRadius={0} background="#E8F2FA">
              {languages.map((lang) => (
                <Menu.Item
                  key={lang.code}
                  isDisabled={lang.code === i18next.language}
                  onClick={() => void i18n.changeLanguage(lang.code)}
                >
                  {lang.code} - {lang.name}
                </Menu.Item>
              ))}
            </Menu.List>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
